import * as React from 'react';
import { filter, concat, set } from 'lodash';
import { createApplication, WorkstreamTrafficControl, entityConfigs, Icons } from '@shapeable/core';
import { themes } from '@shapeable/theme';

const theme = themes.HEART_THEME;

// pre-patch configs to ensure that derived properties are correctly created 

if (entityConfigs.Location) {
  entityConfigs.Location.options = { statistics: 'basic' };
}

if (entityConfigs.ImpactStory) {
  entityConfigs.ImpactStory.label = 'Solution';
  entityConfigs.ImpactStory.slug = 'solution';
}

if (entityConfigs.MaturityStage) {
  entityConfigs.MaturityStage.label = 'Pattern Type';
  entityConfigs.MaturityStage.slug = 'pattern-type';
}


export const application = createApplication({
  theme,
  entityConfigs,
  options: {
    systemsMapping: 'full',
    shapeLogoLabel: 'philanthropy',
    shapeLogoIconColor: 'primary',
    shapeLogoIcon: Icons.Platforms.Philanthropy,
  },
  components: {
  },
});


/* ---- Patch Entities definitions afterwards to ensure sub-props are already set --- */

const entities = application.entities;

delete entities.Trend;
delete entities.Topic;
delete entities.SubTopic;

delete entities.TrendExpertise;
delete entities.TopicExpertise;
delete entities.SubTopicExpertise;

// -- ensure that traffic control team is not shown in lists
entities.Team.list.filterItems = (items) => filter(items, item => item.slug !== 'traffic-control');

// -- show Traffic controllers
entities.Team.list.postBanner = <WorkstreamTrafficControl />;
